import * as amplitude from "@amplitude/analytics-browser"
let customerExperience = amplitude.createInstance()
let candidateExperience = amplitude.createInstance()
const analytics = () => {
  const pricingRoutes = ["/pricing", "/prices", "/see-pricing"]
  const signupRoutes = ["/signup", "/get-started"]
  const personalChecksRoutes = [
    "/personal-background-checks",
    "/personal-background-check",
  ]
  const personalChecksFormIds =
    process.env.GATSBY_PERSONALCHECKS_FORMS.split(",")

  const init = () => {
    customerExperience.init(process.env.GATSBY_AMPLITUDE_API_KEY, {
      autocapture: {
        pageViews: true,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
      },
      instanceName: "customerExperience",
    })
  }

  const initCandidateExperience = () => {
    candidateExperience.init(
      process.env.GATSBY_AMPLITUDE_CANDIDATE_EXPERIENCE_KEY,
      {
        autocapture: {
          pageViews: false,
          sessions: false,
          formInteractions: false,
          fileDownloads: false,
        },
        instanceName: "candidateExperience",
      },
    )
  }

  const getUserId = () => {
    const userId = customerExperience.getUserId()
    return userId ? userId : null
  }
  const getDeviceId = () => {
    const deviceId = customerExperience.getDeviceId()
    return deviceId ? deviceId : null
  }

  const trackPageView = path => {
    if (pricingRoutes.includes(path)) {
      customerExperience.track(EVENT_TYPES.SELF_SERVE_PRICING_PAGE_VIEW)
    }
    if (signupRoutes.includes(path)) {
      customerExperience.track(EVENT_TYPES.SELF_SERVE_SIGNUP_PAGE_VIEW)
    }
    if (personalChecksRoutes.includes(path)) {
      candidateExperience.track(
        EVENT_TYPES.SELF_SERVE_PERSONAL_CHECKS_PAGE_VIEW,
        {
          "Business Entry Point": "Checkr",
        },
      )
    }
    if (path.includes("/lp-direct-mail")) {
      customerExperience.track(
        EVENT_TYPES.SELF_SERVE_MARKETING_DIRECT_MAIL_PAGE_VIEW,
      )
    }
  }

  const trackButtonClick = (e, path) => {
    const { innerText, className } = e.target
    const srcElement = e.srcElement
    const href = srcElement.getAttribute("href")

    if (personalChecksRoutes.includes(path)) {
      if (
        href !== null &&
        (href.includes("apply.checkr") ||
          href.includes("betterfuture") ||
          href.includes(
            "https://apply.checkr.com/apply/better-future-paywall-enabled/3b870f8a5c921a2dbc304bc4d3e2399f",
          ) ||
          href.includes("personal.checkr.com/checks"))
      ) {
        candidateExperience.track(
          EVENT_TYPES.SELF_SERVE_PERSONAL_CHECKS_PAGE_CTA_CLICKED,
          {
            "Business Entry Point": "Checkr",
          },
        )
        candidateExperience.flush()
      }
    }

    if (signupRoutes.includes(path)) {
      if (innerText === "pricing breakdown") {
        customerExperience.track(
          EVENT_TYPES.SELF_SERVE_PRICING_BREAKDOWN_LINK_CLICK,
        )
      } else if (innerText === "Check the status of your background check") {
        customerExperience.track(EVENT_TYPES.SELF_SERVE_CHECK_STATUS_LINK_CLICK)
      } else if (innerText === "Sign in") {
        customerExperience.track(EVENT_TYPES.SELF_SERVE_SIGNUP_LINK_CLICK)
      }
    }

    if (pricingRoutes.includes(path) && typeof className === "string") {
      if (className.includes("basic-plus-plan")) {
        customerExperience.track(
          EVENT_TYPES.SELF_SERVE_PRICING_PAGE_GET_STARTED_CLICK,
          {
            "Get Started Package Type": "Basic+",
          },
        )
      } else if (className.includes("essential-plan")) {
        customerExperience.track(
          EVENT_TYPES.SELF_SERVE_PRICING_PAGE_GET_STARTED_CLICK,
          {
            "Get Started Package Type": "Essential",
          },
        )
      } else if (className.includes("professional-plan")) {
        customerExperience.track(
          EVENT_TYPES.SELF_SERVE_PRICING_PAGE_GET_STARTED_CLICK,
          {
            "Get Started Package Type": "Professional",
          },
        )
      } else if (className.includes("bottomModalCta")) {
        customerExperience.track(
          EVENT_TYPES.SELF_SERVE_PRICING_PAGE_BOTTOM_CTA_CLICK,
        )
      }
    }
    customerExperience.flush()
  }

  const trackFormSubmission = (formId, values, path, tier) => {
    const {
      Annual_Volume_of_Checks__c,
      timetofirstcheckMarketo,
      email,
      selfBackgroundCheck,
    } = values
    const userAttributes = {}

    if (formId) {
      userAttributes["Form ID"] = formId
    }

    if (timetofirstcheckMarketo) {
      userAttributes["Time To First Order"] = timetofirstcheckMarketo
    }

    if (Annual_Volume_of_Checks__c) {
      userAttributes["Annual Volume of Checks"] = Annual_Volume_of_Checks__c
      userAttributes["Tier"] = tier
    }

    if (email) {
      userAttributes["Email"] = email
    }

    if (!path.includes("blog")) {
      customerExperience.track(
        EVENT_TYPES.GENERAL_FORM_SUBMISSION,
        userAttributes,
      )
    }

    if (
      (Annual_Volume_of_Checks__c && Annual_Volume_of_Checks__c === "1") ||
      (selfBackgroundCheck && selfBackgroundCheck === "true") ||
      personalChecksFormIds.includes(formId)
    ) {
      candidateExperience.track(
        EVENT_TYPES.SELF_SERVE_SIGNUP_PAGE_PERSONAL_CHECKS_FORM_SUBMISSION,
        {
          "Form ID": formId,
          "Business Entry Point": "Checkr",
        },
      )
      candidateExperience.flush()
    }

    if (
      ((formId === "1006" || formId === "1388" || formId === "1763") &&
        Annual_Volume_of_Checks__c !== "1") ||
      (selfBackgroundCheck && selfBackgroundCheck === "false")
    ) {
      if (pricingRoutes.includes(path)) {
        customerExperience.track(
          EVENT_TYPES.SELF_SERVE_PRICING_PAGE_COMPLETED,
          userAttributes,
        )
      } else if (
        signupRoutes.includes(path) ||
        selfBackgroundCheck === "false"
      ) {
        customerExperience.track(
          EVENT_TYPES.SELF_SERVE_SIGNUP_PAGE_COMPLETED,
          userAttributes,
        )
      }
    }
    customerExperience.flush()
  }

  return {
    init,
    initCandidateExperience,
    trackPageView,
    trackButtonClick,
    trackFormSubmission,
    getUserId,
    getDeviceId,
  }
}

export { analytics }

const EVENT_TYPES = {
  SELF_SERVE_SIGNUP_PAGE_VIEW:
    "Self-Serve Customer Signup Marketing Signup Page Viewed",
  SELF_SERVE_SIGNUP_PAGE_COMPLETED:
    "Self-Serve Customer Signup Marketing Signup Page Completed",
  SELF_SERVE_PRICING_PAGE_VIEW:
    "Self-Serve Customer Signup Pricing Page Viewed",
  SELF_SERVE_PRICING_PAGE_COMPLETED:
    "Self-Serve Customer Signup Pricing Page Modal Completed",
  SELF_SERVE_PRICING_BREAKDOWN_LINK_CLICK:
    "Self-Serve Customer Signup Marketing Signup Page Price Breakdown Clicked",
  SELF_SERVE_CHECK_STATUS_LINK_CLICK:
    "Self-Serve Customer Signup Marketing Signup Page Check Status Clicked",
  SELF_SERVE_SIGNUP_LINK_CLICK:
    "Self-Serve Customer Signup Marketing Signup Page Sign In Clicked",
  SELF_SERVE_PRICING_PAGE_GET_STARTED_CLICK:
    "Self-Serve Customer Signup Pricing Page Get Started Clicked",
  SELF_SERVE_PRICING_PAGE_BOTTOM_CTA_CLICK:
    "Self-Serve Customer Signup Pricing Page Bottom Sign Up Now Clicked",
  GENERAL_FORM_SUBMISSION: "Self-Serve Marketing Signup Page Completed",
  SELF_SERVE_SIGNUP_PAGE_PERSONAL_CHECKS_FORM_SUBMISSION:
    "Self-Serve Marketing Signup Page - Personal Checks Lead Form Completed",
  SELF_SERVE_PERSONAL_CHECKS_PAGE_VIEW:
    "Self-Serve Marketing Personal Background Check Landing Page Viewed",
  SELF_SERVE_PERSONAL_CHECKS_PAGE_CTA_CLICKED:
    "Self-Serve Marketing Personal Background Check Landing Page CTA Clicked",
  SELF_SERVE_MARKETING_DIRECT_MAIL_PAGE_VIEW:
    "Self-Serve Marketing Direct Mail Landing Page Viewed",
}
